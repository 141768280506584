// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-recipes-js": () => import("./../src/pages/all-recipes.js" /* webpackChunkName: "component---src-pages-all-recipes-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tips-js": () => import("./../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */),
  "component---src-pages-traditional-food-js": () => import("./../src/pages/traditional-food.js" /* webpackChunkName: "component---src-pages-traditional-food-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-recipes-by-category-js": () => import("./../src/templates/recipes-by-category.js" /* webpackChunkName: "component---src-templates-recipes-by-category-js" */)
}

